



















































































































































































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { useMinByDate } from "@/hooks";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataResponseGetListWorkOrder,
  DataResponseWorkOrderCsf,
  RequestResultWorkOrder,
  ResponseWorkOrderDetail,
  WorkOrderLines,
} from "@/models/interface/logistic.interface";
import {
  DataListProduct,
  ResponseProduct,
} from "@/models/interface/product.interface";
import { DataMasterUom } from "@/models/interface/settings.interface";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { productService } from "@/services/product.service";
import { settingsServices } from "@/services/settings.service";
import moment, { Moment } from "moment";
import Vue from "vue";

/**
 * @description
 * hide feature
 * #SUP-319
 * @see https://nostratech.atlassian.net/browse/SUP-319
 */
const TOGGLE_CANCEL_FEATURE = false;

export default Vue.extend({
  name: "ResultWorkOrder",
  data() {
    this.getListProducts = debounceProcess(this.getListProducts, 200);
    this.getListDocumentNumber = debounceProcess(
      this.getListDocumentNumber,
      200
    );
    this.getListCsfNumber = debounceProcess(this.getListCsfNumber, 200);
    this.getListReasonResultWo = debounceProcess(
      this.getListReasonResultWo,
      200
    );
    return {
      TOGGLE_CANCEL_FEATURE,
      DATE_TIME_HOURS_DEFAULT_FORMAT,
      mode: "" as Mode,
      idForView: "" as string,
      woDate: null as Moment | null,
      form: this.$form.createForm(this, { name: "resultworkorder" }),
      formFind: this.$form.createForm(this, { name: "resultworkorderFind" }),
      totalElements: 0 as number,
      limit: 10 as number,
      journalNumber: {
        name: "" as string,
        id: "" as string,
      },
      page: 1 as number,
      view: false as boolean,
      woNumber: "" as string,
      dataDocumentNumber: [] as DataResponseGetListWorkOrder[],
      dataCsfNumber: [] as DataResponseWorkOrderCsf[],
      dataReason: [] as ResponseListMaster[],
      dataStatus: [] as ResponseListMaster[],
      dataWorkOrderDetail: {} as ResponseWorkOrderDetail,
      selectedRowKeys: [] as number[],
      formRulesFind: {
        docNumber: {
          label: "WO Number",
          name: "docNumber",
          placeholder: "WO Number",
          decorator: [
            "docNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        csfNumber: {
          label: "CSF Number",
          name: "csfNumber",
          placeholder: "CSF Number",
          decorator: [
            "csfNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      formRules: {
        woNumber: {
          label: "Work Order Number",
          name: "woNumber",
          placeholder: "Work Order Number",
          decorator: [
            "woNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        woDate: {
          label: "Work Order Date",
          name: "woDate",
          placeholder: "Work Order Date",
          decorator: [
            "woDate",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerAddress: {
          label: "Customer Address",
          name: "customerAddress",
          placeholder: "Customer Address",
          decorator: [
            "customerAddress",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        picName: {
          label: "PIC Name",
          name: "picName",
          placeholder: "PIC Name",
          decorator: [
            "picName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerMaintenanceNumber: {
          label: "Customer Maintenance Number",
          name: "customerMaintenanceNumber",
          placeholder: "<auto generate>",
          decorator: [
            "customerMaintenanceNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        csfNumber: {
          label: "CSF Number",
          name: "csfNumber",
          placeholder: "<auto generate>",
          decorator: [
            "csfNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        mechanicName: {
          label: "Mechanic Name",
          name: "mechanicName",
          placeholder: "Mechanic Name",
          decorator: [
            "mechanicName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        scheduleOrder: {
          label: "Schedule Order",
          name: "scheduleOrder",
          placeholder: "Schedule Order",
          decorator: [
            "scheduleOrder",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "Description",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        journalNumber: {
          label: "Journal Number",
          name: "journalNumber",
          placeholder: "<auto generate>",
          decorator: [
            "journalNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "Status",
          name: "status",
          placeholder: "Status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        reason: {
          label: "Reason",
          name: "reason",
          placeholder: "Reason",
          decorator: [
            "reason",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateTime: {
          label: "Date Time",
          name: "dateTime",
          placeholder: "Date Time",
          decorator: [
            "dateTime",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        notes: {
          label: "Notes",
          name: "notes",
          placeholder: "Notes",
          decorator: [
            "notes",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      loading: {
        table: false,
        loadingDocumentNumber: false,
        loadingCsfNumber: false,
        loadingReason: false,
        loadingStatus: false,
        find: false,
        save: false,
        cancel: false,
      },
      columnsTable: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 120,
          responsiveColSelect: [
            {
              name: "productUsedId",
              placeholder: "Product",
              value: "id",
              options: [] as ResponseProduct[],
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "status",
              placeholder: "Status",
              value: "id",
              options: [] as ResponseListMaster[],
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "reason",
              placeholder: "Reason",
              value: "id",
              options: [] as ResponseListMaster[],
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "uomUsedId",
              placeholder: "UOM Used",
              value: "id",
              options: [] as DataMasterUom[],
              style: "width: 100%",
              disabled: true,
            },
            {
              name: "productReservedId",
              placeholder: "Product Reserve",
              value: "id",
              options: [] as ResponseProduct[],
              style: "width: 100%",
              disabled: true,
            },
            {
              name: "uomReservedId",
              placeholder: "Uom Reserve",
              value: "id",
              options: [] as DataMasterUom[],
              style: "width: 100%",
              disabled: true,
            },
          ],
          responsiveColInput: [
            {
              name: "qtyReturned",
              placeholder: "0",
              disabled: false,
              style: "width: 100%",
            },
            {
              name: "notes",
              placeholder: "Notes",
              disabled: false,
              style: "width: 100%",
            },
          ],
          responsiveColDateMonth: [
            {
              name: "completeDate",
              placeholder: "Select Date",
              style: "width: 100%;",
              disabled: "",
            },
          ],
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          scopedSlots: { customRender: "unitCode" },
          width: 150,
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          scopedSlots: { customRender: "serialNumber" },
          width: 150,
        },
        {
          title: "Category",
          dataIndex: "assetCategory",
          key: "assetCategory",
          scopedSlots: { customRender: "assetCategory" },
          width: 150,
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
          width: 150,
        },
        {
          title: "Product Reserved",
          dataIndex: "productReservedId",
          key: "productReservedId",
          scopedSlots: { customRender: "productReservedId" },
          width: 200,
        },
        {
          title: "Qty Product Reserve",
          dataIndex: "qtyReserved",
          key: "qtyReserved",
          scopedSlots: { customRender: "qtyReserved" },
          width: 200,
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "uomReservedId",
          key: "uomReservedId",
          scopedSlots: { customRender: "uomReservedId" },
          width: 200,
        },
        {
          title: this.$t("lbl_qty_return"),
          dataIndex: "qtyReturned",
          key: "qtyReturned",
          scopedSlots: { customRender: "qtyReturned" },
          width: 200,
        },
        // {
        //   title: "UOM Used",
        //   dataIndex: "uomUsedId",
        //   key: "uomUsedId",
        //   scopedSlots: { customRender: "uomUsedId" },
        //   width: 200,
        // },
        {
          title: this.$t("lbl_part_number"),
          dataIndex: "partNumber",
          key: "partNumber",
          scopedSlots: { customRender: "partNumber" },
          width: 150,
        },
        {
          title: "Track As Asset",
          dataIndex: "trackAsAsset",
          key: "trackAsAsset",
          scopedSlots: { customRender: "trackAsAsset" },
          responsiveColCheckBox: ["trackAsAsset"],
          width: 180,
        },
      ],
      dataList: [] as WorkOrderLines[],
    };
  },
  methods: {
    clickJournal(id: string) {
      this.$router.push("/generaljournal/journal/detail/" + id);
    },
    disabledDate(current: Moment): boolean {
      return useMinByDate(current, this.woDate);
    },
    handleBack() {
      this.$confirm({
        title: this.$t("lbl_leave_page"),
        onOk: () => {
          this.$router.push("/logistic/workorder/result");
        },
        onCancel() {
          return;
        },
      });
    },
    handleCancel() {
      this.$confirm({
        title: `Are you sure want to cancel?`,
        onOk: () => {
          this.loading.cancel = true;
          logisticServices
            .cancelResultWorkOrder(this.idForView)
            .then(() => {
              this.$notification.success({
                description: "Success Cancel",
                message: "Success",
                duration: 30,
              });
              this.$router.push("/logistic/workorder/result");
            })
            .finally(() => (this.loading.cancel = false));
        },
        onCancel() {
          return;
        },
      });
    },
    handleChangeCSFNumber(value) {
      console.log(value);
    },
    handleDocumentNumber(value) {
      this.woNumber = value;
      this.getListCsfNumber("");
      this.formFind.resetFields(["csfNumber"]);
    },
    getListDetailResultWorkOrder() {
      let params: RequestQueryParamsModel = {};
      logisticServices
        .getResultWorkOrderDetail(params, this.idForView)
        .then(response => {
          this.journalNumber.name = response.journalNumber;
          this.journalNumber.id = response.journalId;
          const findColumn = this.columnsTable.find(
            column => column.responsiveColSelect
          )?.responsiveColSelect;
          response.resultWorkOrderLines.forEach((dataForeach, index) => {
            dataForeach.no = (this.page - 1) * this.limit + index + 1;
            dataForeach.key = index;
            if (findColumn) {
              findColumn[4].options.push({
                barcode: "",
                createdDate: "",
                id: dataForeach.productReservedId,
                modifiedDate: "",
                name: dataForeach.productReservedName,
                uomId: "",
                value: "",
                code: "",
                description: "",
                unit: "",
              });
              findColumn[5].options.push({
                barcode: "",
                createdDate: "",
                id: dataForeach.uomReservedId,
                modifiedDate: "",
                name: dataForeach.uomReservedName,
                uomId: "",
                value: "",
                code: "",
                description: "",
                unit: "",
              });
            }
          });
          this.woDate = response.completeDate
            ? moment(response.completeDate)
            : null;
          this.formFind.setFieldsValue({
            docNumber: response.workOrderNumber,
            csfNumber: response.workOrderCSFNumber,
          });
          this.form.setFieldsValue({
            dateTime: response.completeDate
              ? moment(response.completeDate)
              : null,
            status: response.status,
            reason: response.reason,
            notes: response.notes,
            woDate: moment(response.workOrderDate).format("DD-MMM-yyyy"),
            woNumber: response.workOrderNumber,
            customerName: response.customer,
            customerAddress: response.customerAddress,
            picName: response.picName,
            description: response.description,
            scheduleOrder: moment(response.scheduledOrder).format(
              "DD-MMM-yyyy"
            ),
            mechanicName: response.mechanic,
            customerMaintenanceNumber: response.documentNumber,
            journalNumber: response.journalNumber,
            csfNumber: response.workOrderCSFNumber,
          });
          this.dataList = response.resultWorkOrderLines.map(dataMap => {
            return {
              ...dataMap,
              disabledInput: true,
              disabledSelect: true,
              disabledCheckBox: true,
              disabledDateMonth: true,
            };
          });
        });
    },
    addRow(): void {
      const newData = {
        productReservedId: "",
        serialNumber: "",
        assetCategory: "",
        createdDate: "",
        id: "",
        modifiedDate: "",
        notes: "",
        partNumber: "",
        productUsedId: "",
        productLocationId: "",
        productUomId: "",
        qtyAvailable: 0,
        qtyReserved: 0,
        assetId: "",
        unitCode: "",
        trackAsAsset: false,
        status: "",
        reason: "",
        type: "",
        qtyReturned: "",
        completeDate: "",
        locationId: "",
        key: this.dataList.length,
        no:
          this.dataList.length > 0
            ? this.dataList[this.dataList.length - 1].no + 1
            : 1,
      } as WorkOrderLines;
      this.dataList = [...this.dataList, newData];
    },
    onSelectChange(value, key, col): void {
      this.dataList[key] = { ...this.dataList[key], [col]: value };
      this.dataList = this.dataList.slice();
      this.$emit("data-update", this.dataList);
    },
    showConfirmation(): void {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: Messages.CONFIRM_DELETE_TITLE,
          content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
          onOk: () => {
            this.handleDeleteRow();
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select at least one row to delete",
        });
      }
    },
    handleDeleteRow(): void {
      const { dataList } = this;
      this.dataList = dataList.filter(data => {
        return !this.selectedRowKeys.includes(data.key);
      });
      this.dataList.forEach(
        (dataObject, index) =>
          (dataObject.key = (this.page - 1) * this.limit + index + 1)
      );
      this.dataList = this.dataList.slice();
      this.selectedRowKeys = [];
      this.$emit("data-update", this.dataList);
    },
    getCheckboxProps: record => ({
      props: {
        disabled:
          record.productReservedId || record.uomReservedId ? true : false, // Column configuration not to be checked
        name: record.productReservedId || record.uomReservedId,
      },
    }),
    onRowSelect(selectedRowKeys): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.handleFind();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.handleFind();
    },
    checkerUndefined(val): string {
      return val ? val : "";
    },
    handleSave() {
      this.form.validateFields((err, res) => {
        if (err) return;
        let payload: RequestResultWorkOrder = {
          status: res.status,
          reason: res.reason,
          completeDate: this.checkerUndefined(res.dateTime)
            ? moment(res.dateTime).format()
            : "",
          notes: res.notes,
          customerId: this.dataWorkOrderDetail.customerId,
          description: this.checkerUndefined(res.description),
          mechanicId: this.dataWorkOrderDetail.mechanicId,
          picName: this.checkerUndefined(res.picName),
          resultWorkOrderLineDTOS: this.dataList.map(dataMap => {
            return {
              ...dataMap,
              locationId: dataMap.locationId
                ? dataMap.locationId
                : dataMap.productLocationId,
            };
          }),
          scheduledOrder: this.checkerUndefined(res.scheduleOrder)
            ? moment(res.scheduleOrder)
                .set({ hour: 0, minute: 0, second: 0 })
                .utcOffset("+07")
                .format()
            : "",
          workOrderId: this.dataWorkOrderDetail.id,
        };
        this.loading.save = true;
        logisticServices
          .createResultWorkOrder(payload)
          .then(response => {
            this.$notification.success({
              description: Messages.CREATE_SUCCESS,
              message: "Success",
              duration: 30,
            });
            this.$notification.success({
              description: `Customer Maintenance Number : ${response.documentNumber}`,
              message: "Success",
              duration: 30,
            });
            this.$router.push("/logistic/workorder/result");
          })
          .catch(() =>
            this.$notification.error({
              description: Messages.CREATE_FAIL,
              message: "Error",
              duration: 30,
            })
          )
          .finally(() => (this.loading.save = false));
      });
    },
    handleFind() {
      this.formFind.validateFields((err, res) => {
        if (err) return;
        let params = {
          page: this.page - 1,
          limit: this.limit,
        } as RequestQueryParamsModel;
        this.loading.find = true;
        logisticServices
          .getListWorkOrderDetailByIdAndCsf(
            params,
            res.docNumber,
            res.csfNumber
          )
          .then(response => {
            this.form.resetFields(["dateTime"]);
            this.woDate = moment(response.workOrderDate);
            this.form.setFieldsValue({
              woDate: moment(response.workOrderDate).format("DD-MMM-yyyy"),
              woNumber: response.documentNumber,
              customerName: response.customerName,
              customerAddress: response.customerAddress,
              picName: response.picName,
              description: response.description,
              scheduleOrder: moment(response.scheduleOrder).format(
                "DD-MMM-yyyy"
              ),
              mechanicName: response.mechanicName,
              journalNumber: response.journalNumber,
              dateTime: null,
            });
            const findColumn = this.columnsTable.find(
              column => column.responsiveColSelect
            )?.responsiveColSelect;

            response.workOrderLines.forEach((dataObject, index) => {
              dataObject.no = (this.page - 1) * this.limit + index + 1;
              dataObject.key = index;
            });
            this.dataList = response.workOrderLines.map(dataMap => {
              if (findColumn) {
                findColumn[3].options.push({
                  barcode: "",
                  createdDate: "",
                  id: dataMap.productUomId,
                  modifiedDate: "",
                  name: dataMap.productUomName,
                  uomId: "",
                  value: "",
                  code: "",
                  description: "",
                  unit: "",
                });
                findColumn[4].options.push({
                  barcode: "",
                  createdDate: "",
                  id: dataMap.productId,
                  modifiedDate: "",
                  name: dataMap.productName,
                  uomId: "",
                  value: "",
                  code: "",
                  description: "",
                  unit: "",
                });
                findColumn[5].options.push({
                  barcode: "",
                  createdDate: "",
                  id: dataMap.productUomId,
                  modifiedDate: "",
                  name: dataMap.productUomName,
                  uomId: "",
                  value: "",
                  code: "",
                  description: "",
                  unit: "",
                });
              }
              return {
                ...dataMap,
                workOrderLineId: dataMap.id,
                trackAsAsset: false,
                productReservedId: dataMap.productId,
                uomReservedId: dataMap.productUomId,
                //  uomUsedId: dataMap.productUomId,
                productUsedId: dataMap.productId,
                qtyReserved: dataMap.qtyWorkOrder,
                qtyReturned: "0",
              };
            });
            this.dataWorkOrderDetail = response;
          })
          .finally(() => (this.loading.find = false));
      });
    },
    getListDocumentNumber(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `workOrderCSFLine.workOrderCSF.status!PROCESSED`,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search += `_AND_workOrder.documentNumber~*${valueSearch}*`;
      this.loading.loadingDocumentNumber = true;
      logisticServices
        .getListWorkOrder(params)
        .then(response => {
          this.dataDocumentNumber = response.data;
          this.dataDocumentNumber = this.dataDocumentNumber.filter(
            (data, index, self) =>
              index === self.findIndex(t => t.workOrderId === data.workOrderId)
          );
        })
        .finally(() => (this.loading.loadingDocumentNumber = false));
    },
    getListCsfNumber(valueSearch) {
      if (this.woNumber) {
        let params = {
          page: 0,
          limit: 10,
          search: `workOrder.secureId~*${this.woNumber}*_AND_status~UNPROCESSED`,
        } as RequestQueryParamsModel;
        if (valueSearch)
          params.search += `_AND_documentNumber~*${valueSearch}*`;
        this.loading.loadingCsfNumber = true;
        logisticServices
          .getListWorkOrderCsf(params)
          .then(response => {
            this.dataCsfNumber = response.data;
          })
          .finally(() => (this.loading.loadingCsfNumber = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Choose WO Number first to get csf number",
        });
      }
    },
    getProducts(params: RequestQueryParamsModel): Promise<DataListProduct> {
      return productService.listProduct(params);
    },
    async getListProducts(): Promise<void> {
      try {
        const req: RequestQueryParamsModel = {
          limit: 10,
          page: 0,
        };
        const res = await this.getProducts(req);
        const findColumn = this.columnsTable.find(
          column => column.responsiveColSelect
        )?.responsiveColSelect;
        if (findColumn) {
          findColumn[0].options = res.data;
        }
      } finally {
        console.log();
      }
    },
    getListOfBaseUnit(valueSearch): void {
      let params = {
        // baseUnit: true, // tidak dibutuhkan lagi
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `name~*${valueSearch}*`;
      settingsServices.listOfBaseUnit(params).then(res => {
        res.data.forEach((dataObject, index) => {
          dataObject.name = dataObject.unit;
          dataObject.key = index;
        });
        const findColumn = this.columnsTable.find(
          column => column.responsiveColSelect
        )?.responsiveColSelect;
        if (findColumn) {
          findColumn[3].options = res.data;
        }
      });
    },
    getListStatusResultWo(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        name: "STATUS_RWO",
      };
      if (valueSearch) params.search = `value~*${valueSearch}*`;
      this.loading.loadingStatus = true;
      masterServices
        .listMaster(params)
        .then(responseStatus => {
          this.dataStatus = responseStatus;
          const findColumn = this.columnsTable.find(
            column => column.responsiveColSelect
          )?.responsiveColSelect;
          if (findColumn) {
            findColumn[1].options = responseStatus.map(dataMap => {
              return { ...dataMap, name: dataMap.value, id: dataMap.value };
            });
          }
        })
        .finally(() => (this.loading.loadingStatus = false));
    },
    handleSelectStatusWO(value) {
      this.form.setFieldsValue({
        reason: undefined,
      });

      if (value === "Finished") {
        this.getListReasonResultWo(value, "FINISHED");
      } else if (value === "Not Finished") {
        this.getListReasonResultWo(value, "NOT_FINISHED");
      }
    },
    getListReasonResultWo(valueSearch, statusWO) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        name: "STATUS_RWO_" + statusWO,
      };
      if (valueSearch) params.search = `value~*${valueSearch}*`;
      this.loading.loadingReason = true;
      masterServices
        .listMaster(params)
        .then(responseReason => {
          this.dataReason = responseReason;
          const findColumn = this.columnsTable.find(
            column => column.responsiveColSelect
          )?.responsiveColSelect;
          if (findColumn) {
            findColumn[2].options = responseReason.map(dataMap => {
              return { ...dataMap, name: dataMap.value, id: dataMap.value };
            });
          }
        })
        .finally(() => (this.loading.loadingReason = false));
    },
    handleInput(value, key, _column, columnName) {
      this.dataList[key] = { ...this.dataList[key], [columnName]: value };
      this.dataList = this.dataList.slice();
    },
    handleCheckBoxTable(
      checked: boolean,
      key: any,
      _nameColCheckbox: any,
      _value: any
    ): void {
      this.dataList[key].trackAsAsset = checked;
      this.dataList = this.dataList.slice();
    },
    handleSelectTable(value, key, col, recordOptions): void {
      this.dataList[key] = { ...this.dataList[key], [col]: value };
      this.dataList = this.dataList.slice();
    },
    handleDateMonth(_date, key, col, dateString) {
      this.dataList[key] = { ...this.dataList[key], [col]: dateString };
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  created() {
    this.mode = this.$route.meta.mode;
    this.getListProducts();
    this.getListDocumentNumber("");
    this.getListStatusResultWo("");
  },
  mounted() {
    switch (this.mode) {
      case Mode.CREATE:
        break;
      case Mode.VIEW:
        this.view = true; // disable or not
        this.idForView = this.$route.params.id;
        this.getListDetailResultWorkOrder();
        break;
      default:
        break;
    }
  },
});
