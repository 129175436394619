var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Result Customer Maintenance" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      labelAlign: "left",
                                      layout: "vertical",
                                      form: _vm.formFind
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            span: 12,
                                            xs: 24,
                                            sm: 24,
                                            md: 12
                                          }
                                        },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRulesFind.docNumber
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRulesFind
                                                          .docNumber.decorator,
                                                      expression:
                                                        "formRulesFind.docNumber.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRulesFind
                                                        .docNumber.name,
                                                    placeholder:
                                                      _vm.formRulesFind
                                                        .docNumber.placeholder,
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading
                                                        .loadingDocumentNumber,
                                                    allowClear: true,
                                                    disabled: _vm.view
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListDocumentNumber(
                                                        value
                                                      )
                                                    },
                                                    change:
                                                      _vm.handleDocumentNumber
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataDocumentNumber,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value:
                                                            data.workOrderId
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.workOrderNumber
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.workOrderNumber
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRulesFind.csfNumber
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRulesFind
                                                          .csfNumber.decorator,
                                                      expression:
                                                        "formRulesFind.csfNumber.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRulesFind
                                                        .csfNumber.name,
                                                    placeholder:
                                                      _vm.formRulesFind
                                                        .csfNumber.placeholder,
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading
                                                        .loadingCsfNumber,
                                                    allowClear: true,
                                                    disabled: _vm.view
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListCsfNumber(
                                                        value
                                                      )
                                                    },
                                                    change:
                                                      _vm.handleChangeCSFNumber
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCsfNumber,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.csfNo
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.csfNo
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    loading: _vm.loading.find,
                                                    disabled: _vm.view
                                                  },
                                                  on: { click: _vm.handleFind }
                                                },
                                                [_vm._v(" Find ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-tabs",
                                { attrs: { type: "card" } },
                                [
                                  _c(
                                    "a-tab-pane",
                                    {
                                      key: "result",
                                      staticClass: "bordertabs p-4",
                                      attrs: { tab: "Result" }
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        { attrs: { gutter: [16, 16] } },
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "a-form",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      layout: "vertical",
                                                      form: _vm.form
                                                    }
                                                  },
                                                  "a-form",
                                                  _vm.formItemLayout,
                                                  false
                                                ),
                                                [
                                                  _c(
                                                    "a-row",
                                                    {
                                                      attrs: {
                                                        gutter: [16, 16]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .woNumber
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .woNumber
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.woNumber.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .woNumber
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .woNumber
                                                                      .placeholder
                                                                  ),
                                                                  disabled: true
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .woDate
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .woDate
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.woDate.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .woDate
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .woDate
                                                                      .placeholder
                                                                  ),
                                                                  disabled: true
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .picName
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .picName
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.picName.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .picName
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .picName
                                                                      .placeholder
                                                                  ),
                                                                  disabled: true
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .customerMaintenanceNumber
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .customerMaintenanceNumber
                                                                        .decorator,
                                                                    expression:
                                                                      "\n                                  formRules.customerMaintenanceNumber\n                                    .decorator\n                                "
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .customerMaintenanceNumber
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .customerMaintenanceNumber
                                                                      .placeholder
                                                                  ),
                                                                  disabled: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .csfNumber
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .csfNumber
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.csfNumber.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .csfNumber
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .csfNumber
                                                                      .placeholder
                                                                  ),
                                                                  disabled: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "a-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .customerName
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .customerName
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.customerName.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .customerName
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .customerName
                                                                      .placeholder
                                                                  ),
                                                                  disabled: true
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .customerAddress
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .customerAddress
                                                                        .decorator,
                                                                    expression:
                                                                      "\n                                  formRules.customerAddress.decorator\n                                "
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .customerAddress
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .customerAddress
                                                                      .placeholder
                                                                  ),
                                                                  disabled: true
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .mechanicName
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .mechanicName
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.mechanicName.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .mechanicName
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .mechanicName
                                                                      .placeholder
                                                                  ),
                                                                  disabled: true
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .scheduleOrder
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .scheduleOrder
                                                                        .decorator,
                                                                    expression:
                                                                      "\n                                  formRules.scheduleOrder.decorator\n                                "
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .scheduleOrder
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .scheduleOrder
                                                                      .placeholder
                                                                  ),
                                                                  disabled: true
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .description
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-textarea", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .description
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.description.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .description
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .description
                                                                      .placeholder
                                                                  ),
                                                                  disabled: true,
                                                                  "auto-size": {
                                                                    minRows: 3,
                                                                    maxRows: 5
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "a-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _vm.mode !== "create"
                                                            ? _c(
                                                                "a-form-item",
                                                                {
                                                                  attrs: {
                                                                    label: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .journalNumber
                                                                        .label
                                                                    )
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-button",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "blue",
                                                                        background:
                                                                          "none"
                                                                      },
                                                                      attrs: {
                                                                        type:
                                                                          "dashed"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.clickJournal(
                                                                            _vm
                                                                              .journalNumber
                                                                              .id
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .journalNumber
                                                                            .name
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .status
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .status
                                                                          .decorator,
                                                                      expression:
                                                                        "formRules.status.decorator"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .status
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .status
                                                                        .placeholder
                                                                    ),
                                                                    showSearch: true,
                                                                    "option-filter-prop":
                                                                      "children",
                                                                    "filter-option":
                                                                      _vm.filterOption,
                                                                    loading:
                                                                      _vm
                                                                        .loading
                                                                        .loadingStatus,
                                                                    allowClear: true,
                                                                    disabled:
                                                                      _vm.view
                                                                  },
                                                                  on: {
                                                                    search: function(
                                                                      value
                                                                    ) {
                                                                      return _vm.getListStatusResultWo(
                                                                        value
                                                                      )
                                                                    },
                                                                    change:
                                                                      _vm.handleSelectStatusWO
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.dataStatus,
                                                                  function(
                                                                    data,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          value:
                                                                            data.value
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-tooltip",
                                                                          [
                                                                            _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "title"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data.value
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.value
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .reason
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .reason
                                                                          .decorator,
                                                                      expression:
                                                                        "formRules.reason.decorator"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .reason
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .reason
                                                                        .placeholder
                                                                    ),
                                                                    "option-filter-prop":
                                                                      "children",
                                                                    "filter-option":
                                                                      _vm.filterOption,
                                                                    loading:
                                                                      _vm
                                                                        .loading
                                                                        .loadingReason,
                                                                    allowClear: true,
                                                                    disabled:
                                                                      _vm.view
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.dataReason,
                                                                  function(
                                                                    data,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          value:
                                                                            data.value
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-tooltip",
                                                                          [
                                                                            _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "title"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data.value
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.value
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .dateTime
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-date-picker",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .dateTime
                                                                          .decorator,
                                                                      expression:
                                                                        "formRules.dateTime.decorator"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .dateTime
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .dateTime
                                                                        .placeholder
                                                                    ),
                                                                    disabled:
                                                                      _vm.view,
                                                                    format:
                                                                      _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                                                                    "disabled-date":
                                                                      _vm.disabledDate,
                                                                    "show-time":
                                                                      ""
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .notes.label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-textarea", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .notes
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.notes.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .notes
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .notes
                                                                      .placeholder
                                                                  ),
                                                                  disabled:
                                                                    _vm.view,
                                                                  "auto-size": {
                                                                    minRows: 3,
                                                                    maxRows: 5
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-row",
                                        { attrs: { gutter: [16, 16] } },
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "a-space",
                                                {
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                },
                                                [
                                                  _c("TableCustom", {
                                                    attrs: {
                                                      idTable:
                                                        "tableresultworkorder",
                                                      dataSource: _vm.dataList,
                                                      columns: _vm.columnsTable,
                                                      paginationcustom: true,
                                                      defaultPagination: false,
                                                      loading:
                                                        _vm.loading.table,
                                                      scroll: { x: 2600 },
                                                      handleCheckBox:
                                                        _vm.handleCheckBoxTable,
                                                      handleInput:
                                                        _vm.handleInput,
                                                      handleSelect:
                                                        _vm.handleSelectTable,
                                                      handleDateMonth:
                                                        _vm.handleDateMonth,
                                                      onSelectChange:
                                                        _vm.onRowSelect,
                                                      getCheckboxProps:
                                                        _vm.getCheckboxProps,
                                                      selectedRowKeys:
                                                        _vm.selectedRowKeys
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center"
                                                    },
                                                    [
                                                      _c("Pagination", {
                                                        attrs: {
                                                          idPagination:
                                                            "paginationresultworkorder",
                                                          total: 10,
                                                          pageSizeSet: 10
                                                        }
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "ml-auto"
                                                        },
                                                        [
                                                          _c(
                                                            "a-space",
                                                            [
                                                              _c(
                                                                "a-button",
                                                                {
                                                                  attrs: {
                                                                    loading:
                                                                      _vm
                                                                        .loading
                                                                        .cancel
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.handleBack
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "lbl_back"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm.TOGGLE_CANCEL_FEATURE &&
                                                              _vm.view &&
                                                              _vm.$can(
                                                                "delete",
                                                                "result-work-order"
                                                              )
                                                                ? _c(
                                                                    "a-button",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "danger",
                                                                        loading:
                                                                          _vm
                                                                            .loading
                                                                            .cancel
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.handleCancel
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "lbl_cancel"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              !_vm.view &&
                                                              _vm.$can(
                                                                "create",
                                                                "result-work-order"
                                                              )
                                                                ? _c(
                                                                    "a-button",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "primary",
                                                                        loading:
                                                                          _vm
                                                                            .loading
                                                                            .save
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.handleSave
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "lbl_save"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }